.homepage{
    color: white;

}
.homepage h1{
    font-size: 90px;
        letter-spacing: 16px;
        text-transform: uppercase;
    
}
.bg-1 .button {
    color: #050505;
    border-color: #37474f;
}
.button--border-thick {
    border: 2px solid #37474f !important ;
}
.button--text-upper {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.button--size-s {
    font-size: 18px;
}
.button {
    float: left;
    min-width: 150px;
    max-width: 250px;
    display: block;
    margin: 1em;
    padding: 1em 2em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}

.button:focus {
    outline: none;
}
.button--wayra {
    overflow: hidden;
    width: 245px;
    -webkit-transition: border-color 0.3s, color 0.3s;
    transition: border-color 0.3s, color 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--wayra::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: #37474f;
    z-index: -1;
    -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.button--wayra:hover {
    color: #fff;
    border-color: var(--main-color);
}

.button--wayra.button--inverted:hover {
    color: var(--main-color);
    border-color: #fff;
}

.button--wayra:hover::before {
    opacity: 1;
    background-color: var(--main-color);
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--wayra.button--inverted:hover::before {
    background-color: #fff;
}


@media (max-width:450px) {
    .homepage h1 {
            font-size: 80px;
    
        }
}
@media (max-width:390px) {
    .homepage h1 {
            font-size: 60px;
    
        }
                .button--size-s {
                    font-size: 14px;
                }
}
@media (max-width:320px) {
    .homepage h1 {
            font-size: 48px;
    
        }
                .button--size-s {
                    font-size: 12px;
                }
  .button {
           margin-bottom: 2em;                   
                                }
}