.button--text-thick {
    font-weight: 600;
}
.commpage{
    position: relative;
    overflow: hidden;
}
.back-arrow{
    position: absolute;
    left: 20px;
    top: 20px;
    color: white;
    cursor: pointer;
}

.button--moema {
    padding: 1.5em 3em;
    border-radius: 50px;
    background: #4e474714;
    font-size: 18px;
    color: #000;
    -webkit-transition: background-color 0.3s, color 0.3s;
    transition: background-color 0.3s, color 0.3s;
}

.button--moema.button--inverted {
    background: #ECEFF1;
    color: #37474f;
}

.button--moema::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    bottom: -20px;
    right: -20px;
    background: inherit;
    border-radius: 50px;
    z-index: -1;
    opacity: 0.4;
    -webkit-transform: scale3d(0.8, 0.5, 1);
    transform: scale3d(0.8, 0.5, 1);
}




.button--moema:hover {
    -webkit-transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
    transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
    color: #ECEFF1;
    background-color: var(--main-color);
    -webkit-animation: anim-moema-1 0.3s forwards;
    animation: anim-moema-1 0.3s forwards;
}

.button--moema.button--inverted:hover {
    color: #ECEFF1;
    background-color: #7986cb;
}

.button--moema:hover::before {
    -webkit-animation: anim-moema-2 0.3s 0.3s forwards;
    animation: anim-moema-2 0.3s 0.3s forwards;
}

@-webkit-keyframes anim-moema-1 {
    60% {
        -webkit-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
    }

    85% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
        transform: scale3d(1.1, 1.1, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes anim-moema-1 {
    60% {
        -webkit-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
    }

    85% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
        transform: scale3d(1.1, 1.1, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes anim-moema-2 {
    to {
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes anim-moema-2 {
    to {
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}